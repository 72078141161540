import React, { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import "./styles/paquetes.css";

interface RegistroProps {
    show: boolean,
    handleClose: (formData?:any) => void,
}

export function FormFactura({ show, handleClose }: RegistroProps) {
    const [formData,setFormData] = useState({
        rfc :'',
        razonSocial:'',
        codigoPostal:''
    })
     

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value.toLowerCase(),
        });
    };

    const handleSubmit = () => {
        handleClose(formData);
    };

    const handleCleanPrevClos = () =>{
        setFormData({
            rfc: '',
            razonSocial:'',
            codigoPostal: ''
        });
        handleClose();
    }

    

    return (
        <div >
        <Modal className='modalRegistro'  show={show} onHide={handleCleanPrevClos}>
            <Modal.Header className='modalBody'  closeButton>
                    <Modal.Title className='title'>Formulario para Facturación</Modal.Title>   
            </Modal.Header>
            <Modal.Body className='modalBody' >
                <Form className='formulario'>
                <Form.Group className="mb-3" controlId="formBasicRazonSocial">
                        <Form.Control
                            type="text"
                            name="razonSocial"
                            placeholder="Razón social"
                            value={formData.razonSocial}
                            onChange={handleInputChange} 
                            className='inputStyle'
                        />
                        </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicRfc">
                        <Form.Control
                            type="text"
                            name="rfc"
                            placeholder="RFC"
                            value={formData.rfc}
                            onChange={handleInputChange} 
                            className='inputStyle'
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCodigoPostal">
                        <Form.Control
                            type="text"
                            name="codigoPostal"
                            placeholder="Codigo Postal"
                            value={formData.codigoPostal}
                            onChange={handleInputChange} 
                            className='inputStyle'
                        />
                    </Form.Group>
                </Form>
                
                    <p className='parrafoRegistro'>Al adquirir un paquete con módulo de facturación es necesario que registres estos campos</p>
            </Modal.Body>
            <Modal.Footer  className='modalFooter'>
                <Button className='btnGuardar' variant="primary" onClick={handleSubmit} disabled={!formData.codigoPostal || !formData.rfc}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
        </div>
       
    );
}