const dataNosotros = [
  {
    id: 1,
    name: "Punto de venta",
    description: "La ciberseguridad protege sistemas, redes y datos de robos y accesos no autorizados, implementando protocolos de protección avanzados.",
    services: [
      {
        id: 1,
        service: "Seguridad web"
      },
      {
        id: 2,
        service: "Seguridad de red"
      }
    ],
    icon: "/assets/icons/ic_cloudsolutions.png"
  },
  {
    id: 2,
    name: "Compras",
    description: "La ciberseguridad protege sistemas, redes y datos de robos y accesos no autorizados, implementando protocolos de protección avanzados.",
    services: [
      {
        id: 1,
        service: "Seguridad web"
      },
      {
        id: 2,
        service: "Seguridad de red"
      }
    ],
    icon: "/assets/icons/ic_cloudsolutions.png"
  },
  {
    id: 3,
    name: "Análisis de ventas",
    description: "La ciberseguridad protege sistemas, redes y datos de robos y accesos no autorizados, implementando protocolos de protección avanzados.",
    services: [
      {
        id: 1,
        service: "Seguridad web"
      },
      {
        id: 2,
        service: "Seguridad de red"
      }
    ],
    icon: "/assets/icons/ic_cloudsolutions.png"
  },
];

export default dataNosotros;