"use client";
import './styles/Inicio.css'
import { iconsInicio } from './Inicio.data'
import { MotionTransition } from "../../utils/MotionTansition"
import Container from 'react-bootstrap/Container';
import { Button } from 'react-bootstrap';
import { Reveal } from "../../utils/Reveal/Reveal"

export function Inicio() {
 

 
  return (
      <div className="first-screen">
        <Container className='first-header'>
        <Reveal>
        <img src="/assets/logoTechCode.png" alt="Logo" />
          </Reveal>
        </Container>
        <MotionTransition>
        <p>DATOS, SOFTWARE Y PLATAFORMAS. <br />EFICIENCIA MÁXIMA, ÉXTITO SOSTENIBLE</p>
        </MotionTransition>
        <div className='contact'>
          <Button className='boton-vertical' variant="primary" size="lg" >CONTACTO</Button>
        </div>
        <div className='social-media'>
          {iconsInicio.map(({ id, image }) => (
            <div key={id}>
              <img src={image} className="link-nav" width="40" alt='icon' height="40" />
            </div>
          ))}
        </div>
       
      </div>
  )
}
