import { useEffect, useRef } from "react";
import Spinner from 'react-bootstrap/Spinner';
import "./spinnerFunction.css";

export function SpinnerFunction() {
   
    return (
        <div className="custom-loader">
        <div className="overlay"></div>
        <div className="spinner-container">
          <Spinner animation="border" role="status" className="custom-spinner"> 
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </div>
    )
}
