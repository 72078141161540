export const PaquetesData = [
    {
        id:10,
        usuarios:'10 usuarios'
    },
    {
        id:20,
        usuarios:'20 usuarios'
    },
    {
        id:30,
        usuarios:'30 usuarios'
    },
    {
        id:40,
        usuarios:'40 usuarios'
    }
]

export const planes = [
    [
        {
            "id": 1,
            "idPlanPaypal": "P-8E8705556J216210KMTYCDPA",
            "idRol": 0,
            "nombre": "Plan Básico Mensual",
            "precio": 109,
            "moneda": "MXN",
            "frecuencia": "MONTH",
            "iva": 16,
            "ivaIncluido": 0,
            "fechaCreacion": "30-08-2023",
            "descripcion": "Suscripcion mensual de uso básico",
            "estatus": "ACTIVE",
            "usuarios":10,
            "updated_at": "2024-03-29T16:27:45.000000Z",
            "created_at": "2024-03-29T16:27:45.000000Z"
        },
        {
            "id": 2,
            "idPlanPaypal": "P-1AB98616PM676542VMTYC7RA",
            "idRol": 0,
            "nombre": "Plan Administrativo Mensual",
            "precio": 149,
            "moneda": "MXN",
            "frecuencia": "MONTH",
            "iva": 16,
            "ivaIncluido": 0,
            "fechaCreacion": "30-08-2023",
            "descripcion": "Suscripcion mensual de uso básico",
            "estatus": "ACTIVE",
            "usuarios":20,
            "updated_at": "2024-03-29T16:27:45.000000Z",
            "created_at": "2024-03-29T16:27:45.000000Z"
        },
    ],
    [
        {
            "id": 3,
            "idPlanPaypal": "P-2GX582532E415602JMTYDB7I",
            "idRol": 0,
            "nombre": "Plan Total Mensual",
            "precio": 249,
            "moneda": "MXN",
            "frecuencia": "MONTH",
            "iva": 16,
            "ivaIncluido": 0,
            "fechaCreacion": "30-08-2023",
            "descripcion": "Suscripcion mensual de uso básico",
            "estatus": "ACTIVE",
            "usuarios":10,
            "updated_at": "2024-03-29T16:27:45.000000Z",
            "created_at": "2024-03-29T16:27:45.000000Z"
        },
        {
            "id": 4,
            "idPlanPaypal": "P-4UH31423DX2342549MYMY3BY",
            "idRol": 0,
            "nombre": "Punto de Venta Prueba 2",
            "precio": 59,
            "moneda": "MXN",
            "frecuencia": "MONTH",
            "iva": null,
            "ivaIncluido": 1,
            "fechaCreacion": "2024-04-12 19:37:43",
            "descripcion": "Punto de venta para tus productos,Impresión de tickets,Lector de códigos de barra,Corte de caja,Ingresos y retiros de efectivo,Multi usuarios,Multi almacenes, Productos ilimitados,Entradas y salidas de producto,Análisis de ventas.",
            "estatus": "INACTIVE",
            "usuarios":20,
            "updated_at": "2024-04-12T19:37:43.000000Z",
            "created_at": "2024-04-12T19:37:43.000000Z"
        },
    ],
    [
        {
            "id": 5,
            "idPlanPaypal": "P-0U745088UM4784645MYMZTTI",
            "idRol": 0,
            "nombre": "plan de gratis",
            "precio": 89,
            "moneda": "MXN",
            "frecuencia": "MONTH",
            "iva": null,
            "ivaIncluido": 1,
            "fechaCreacion": "2024-04-12 20:30:06",
            "descripcion": "Punto de venta para tus productos,Impresión de tickets,Lector de códigos de barra,Corte de caja,Ingresos y retiros de efectivo,Multi usuarios,Multi almacenes, Productos ilimitados,Entradas y salidas de producto,Análisis de ventas.",
            "estatus": "ACTIVE",
            "usuarios":10,
            "updated_at": "2024-04-15T20:40:37.000000Z",
            "created_at": "2024-04-12T20:30:06.000000Z"
        },
        {
            "id": 6,
            "idPlanPaypal": "P-94404436E2384313XMYMZU7Q",
            "idRol": 0,
            "nombre": "plan de PRUEBA",
            "precio": 89,
            "moneda": "MXN",
            "frecuencia": "MONTH",
            "iva": null,
            "ivaIncluido": 1,
            "fechaCreacion": "2024-04-12 20:33:03",
            "descripcion": "Punto de venta para tus productos,Impresión de tickets,Lector de códigos de barra,Corte de caja,Ingresos y retiros de efectivo,Multi usuarios,Multi almacenes, Productos ilimitados,Entradas y salidas de producto,Análisis de ventas.",
            "estatus": "ACTIVE",
            "usuarios":20,
            "updated_at": "2024-04-12T20:33:03.000000Z",
            "created_at": "2024-04-12T20:33:03.000000Z"
        },
        {
            "id": 7,
            "idPlanPaypal": "P-2YK02079S93230522MYMZ5YI",
            "idRol": 0,
            "nombre": "Punto Venta",
            "precio": 99,
            "moneda": "MXN",
            "frecuencia": "MONTH",
            "iva": null,
            "ivaIncluido": 1,
            "fechaCreacion": "2024-04-12 20:51:45",
            "descripcion": "Punto de venta para tus productos,Impresión de tickets,Lector de códigos de barra,Corte de caja,Ingresos y retiros de efectivo,Multi usuarios,Multi almacenes, Productos ilimitados,Entradas y salidas de producto,Caducidades de productos,Análisis de ventas.",
            "estatus": "ACTIVE",
            "usuarios":30,
            "updated_at": "2024-04-12T20:51:45.000000Z",
            "created_at": "2024-04-12T20:51:45.000000Z"
        },
        {
            "id": 8,
            "idPlanPaypal": "P-1M438024M6551093XMYM2CJY",
            "idRol": 0,
            "nombre": "Punto de Venta",
            "precio": 99,
            "moneda": "MXN",
            "frecuencia": "MONTH",
            "iva": null,
            "ivaIncluido": 1,
            "fechaCreacion": "2024-04-12 21:01:27",
            "descripcion": "Punto de venta para tus productos,Impresión de tickets,Lector de códigos de barra,Corte de caja,Ingresos y retiros de efectivo,Multi usuarios,Multi almacenes, Productos ilimitados,Entradas y salidas de producto,Análisis de ventas.",
            "estatus": "ACTIVE",
            "usuarios":40,
            "updated_at": "2024-04-12T21:01:27.000000Z",
            "created_at": "2024-04-12T21:01:27.000000Z"
        },
    ],
    [
        {
            "id": 9,
            "idPlanPaypal": "P-3DR67162FG1819621MYM2D7Q",
            "idRol": 0,
            "nombre": "plan de muestra",
            "precio": 100,
            "moneda": "MXN",
            "frecuencia": "MONTH",
            "iva": null,
            "ivaIncluido": 1,
            "fechaCreacion": "2024-04-12 21:05:03",
            "descripcion": "Punto de venta para tus productos,Impresión de tickets,Lector de códigos de barra,Corte de caja,Ingresos y retiros de efectivo,Multi usuarios,Multi almacenes, Productos ilimitados,Entradas y salidas de producto,Análisis de ventas.",
            "estatus": "ACTIVE",
            "usuarios":10,
            "updated_at": "2024-04-12T21:05:03.000000Z",
            "created_at": "2024-04-12T21:05:03.000000Z"
        },
        {
            "id": 10,
            "idPlanPaypal": "P-8XU514661P4600153MYM2JNY",
            "idRol": 0,
            "nombre": "Mega prueba",
            "precio": 200,
            "moneda": "MXN",
            "frecuencia": "MONTH",
            "iva": null,
            "ivaIncluido": 1,
            "fechaCreacion": "2024-04-12 21:16:39",
            "descripcion": "aaaaa",
            "estatus": "INACTIVE",
            "usuarios":20,
            "updated_at": "2024-04-15T20:41:41.000000Z",
            "created_at": "2024-04-12T21:16:39.000000Z"
        },
        {
            "id": 11,
            "idPlanPaypal": "P-39G54863KS2538608MYM2KII",
            "idRol": 0,
            "nombre": "otra mas",
            "precio": 230,
            "moneda": "MXN",
            "frecuencia": "MONTH",
            "iva": null,
            "ivaIncluido": 1,
            "fechaCreacion": "2024-04-12 21:18:25",
            "descripcion": "sss",
            "estatus": "ACTIVE",
            "usuarios":30,
            "updated_at": "2024-04-12T21:18:25.000000Z",
            "created_at": "2024-04-12T21:18:25.000000Z"
        },
        {
            "id": 12,
            "idPlanPaypal": "P-3110599268087805VMYM2K3Q",
            "idRol": 0,
            "nombre": "una mas",
            "precio": 26,
            "moneda": "MXN",
            "frecuencia": "MONTH",
            "iva": null,
            "ivaIncluido": 1,
            "fechaCreacion": "2024-04-12 21:19:42",
            "descripcion": "assd",
            "estatus": "ACTIVE",
            "usuarios":40,
            "updated_at": "2024-04-12T21:19:42.000000Z",
            "created_at": "2024-04-12T21:19:42.000000Z"
        },
        {
            "id": 13,
            "idPlanPaypal": "P-05M40566B9078121MMYM3YHQ",
            "idRol": 0,
            "nombre": "plan de muestra",
            "precio": 100,
            "moneda": "MXN",
            "frecuencia": "MONTH",
            "iva": null,
            "ivaIncluido": 1,
            "fechaCreacion": "2024-04-12 22:56:30",
            "descripcion": "Punto de venta para tus productos,Impresión de tickets,Lector de códigos de barra,Corte de caja,Ingresos y retiros de efectivo,Multi usuarios,Multi almacenes, Productos ilimitados,Entradas y salidas de producto,Análisis de ventas.",
            "estatus": "ACTIVE",
            "usuarios":50,
            "updated_at": "2024-04-12T22:56:30.000000Z",
            "created_at": "2024-04-12T22:56:30.000000Z"
        },
    ],
    [
        {
            "id": 14,
            "idPlanPaypal": "P-1R22856669634880WMYM3ZSI",
            "idRol": 0,
            "nombre": "plan de muestra dos",
            "precio": 100,
            "moneda": "MXN",
            "frecuencia": "MONTH",
            "iva": null,
            "ivaIncluido": 1,
            "fechaCreacion": "2024-04-12 22:59:22",
            "descripcion": "Punto de venta para tus productos,Impresión de tickets,Lector de códigos de barra,Corte de caja,Ingresos y retiros de efectivo,Multi usuarios,Multi almacenes, Productos ilimitados,Entradas y salidas de producto,Análisis de ventas.",
            "estatus": "ACTIVE",
            "usuarios":10,
            "updated_at": "2024-04-12T22:59:22.000000Z",
            "created_at": "2024-04-12T22:59:22.000000Z"
        },
        {
            "id": 15,
            "idPlanPaypal": "P-1J239109VH460350BMYM37PY",
            "idRol": 0,
            "nombre": "Ultimo plan",
            "precio": 250,
            "moneda": "MXN",
            "frecuencia": "MONTH",
            "iva": null,
            "ivaIncluido": 1,
            "fechaCreacion": "2024-04-12 23:12:00",
            "descripcion": "dsafgsdg",
            "estatus": "ACTIVE",
            "usuarios":20,
            "updated_at": "2024-04-12T23:12:00.000000Z",
            "created_at": "2024-04-12T23:12:00.000000Z"
        },
        {
            "id": 16,
            "idPlanPaypal": "P-1DY665177G574822SMYM4BMY",
            "idRol": 0,
            "nombre": "uno más",
            "precio": 52,
            "moneda": "MXN",
            "frecuencia": "MONTH",
            "iva": null,
            "ivaIncluido": 1,
            "fechaCreacion": "2024-04-12 23:16:03",
            "descripcion": "hbngtfredbtgfbhntghbterhbtrhnbtgrwbrgb",
            "estatus": "ACTIVE",
            "usuarios":30,
            "updated_at": "2024-04-12T23:16:03.000000Z",
            "created_at": "2024-04-12T23:16:03.000000Z"
        },
        {
            "id": 17,
            "idPlanPaypal": "P-0WX60444DW739535LMYOWPNI",
            "idRol": 13,
            "nombre": "Prueba Rol",
            "precio": 52,
            "moneda": "MXN",
            "frecuencia": "MONTH",
            "iva": null,
            "ivaIncluido": 1,
            "fechaCreacion": "2024-04-15 17:45:23",
            "descripcion": "prueba de plan",
            "estatus": "ACTIVE",
            "usuarios":40,
            "updated_at": "2024-04-15T17:45:23.000000Z",
            "created_at": "2024-04-15T17:45:23.000000Z"
        }
    ]
]
  