import Modal from "react-modal";
import "./Subscription.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useFetch } from "../../../Services/suscripciones/useFetch";
import { productionRoutes } from "../../../environments/environments";
import { useEffect, useState } from "react";
import { format, addMonths } from "date-fns";

interface propsDescription {
  plan: any;
  isOpen: boolean;
  isClose: () => void;
  formRegistro: any;
}

export default function SubscriptionButton({
  plan,
  isOpen,
  isClose,
  formRegistro,
}: propsDescription) {
  const [dataSuscription, setDataSuscription] = useState({} as any);
  const [user, setUser] = useState({} as any);

  const usuarioString = localStorage.getItem("usuario");
  const usuario = JSON.parse(usuarioString!);

  const paypalOptions = {
    clientId:
      "AcsFR3Jne2hJYWkgvB5dpq20GbW1XeezxtiYC7COzKb8yhZfu-nTK4cf6W3yl5P45_M66VMJ6GPzYnzS",
    currency: "MXN",
    vault: true,
  };

  const createSubscription = (data: any, actions: any) => {
    const currentDate = new Date();
    const formattedCurrentDate = format(currentDate, "dd-MM-yyyy");
    const nextMonthDate = addMonths(currentDate, 1);
    const formattedNextMonthDate = format(nextMonthDate, "dd-MM-yyyy");
    console.log({
      idUsuario: usuario.id,
      idRol: plan.idRol,
      idPlan: plan.idPlanPaypal,
      subscriptionID: data.subscriptionID,
      fechaInicio: formattedCurrentDate,
      fechaFin: formattedNextMonthDate,
      facilitatorAccessToken: data.facilitatorAccessToken,
      paymentSource: data.paymentSource,
      orderID: data.orderID,
      correo: usuario.email,
      permisos: plan.permisos,
      razonSocial: "user",
      rfc: formRegistro.rfc,
      email: usuario.email,
      codigoPostal: formRegistro.rfc,
    });
    return actions.subscription.create({
      plan_id: plan.idPlanPaypal,
    });
  };

  const url = productionRoutes.createSuscription;

  const { data, loading, fetchData } = useFetch(
    url,
    "POST",
    dataSuscription,
    true
  );

  const onApprove = (data: any, actions: any): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const currentDate = new Date();
      const formattedCurrentDate = format(currentDate, "dd-MM-yyyy");
      const nextMonthDate = addMonths(currentDate, 1);
      const formattedNextMonthDate = format(nextMonthDate, "dd-MM-yyyy");

      if (data.subscriptionID) {
        setDataSuscription({
          idUsuario: usuario.id,
          idRol: plan.idRol,
          idPlan: plan.idPlanPaypal,
          subscriptionID: data.subscriptionID,
          fechaInicio: formattedCurrentDate,
          fechaFin: formattedNextMonthDate,
          facilitatorAccessToken: data.facilitatorAccessToken,
          paymentSource: data.paymentSource,
          orderID: data.orderID,
          correo: usuario.email,
          permisos: plan.permisos,
          razonSocial: "El Vendedor",
          rfc: formRegistro.rfc,
          email: usuario.email,
          codigoPostal: formRegistro.rfc,
        });

        resolve();
      } else {
        reject(new Error("No se recibió subscriptionID en la respuesta."));
      }
    });
  };

  useEffect(() => {
    if (dataSuscription.idUsuario) {
      fetchData();
    }
  }, [dataSuscription]);

  useEffect(() => {
    if (data) {
      console.log(data);
    }
  }, [data]);

  useEffect(() => {
    setUser(usuario);
    setTimeout(() => {
      console.log(plan, formRegistro, usuario, user);
    }, 1000);
  }, []);

  const customStyles:any = {
    content: {
      width: "400px",
      height: "400px",
      borderRadius: "10px",
      margin: "auto",
      backgroundColor: "black",
      zIndex:100000
    },
    overlay: {
    position: "fixed",
    zIndex: 100000,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const appRoot = document.getElementById("root");

  return (
    <Modal
      appElement={appRoot || undefined}
      style={customStyles}
      isOpen={isOpen}
    >
      <div className="cerrar" onClick={isClose}>
  &times;
</div>
  <div className="planSelect">
    <h5>Plan seleccionado :</h5>
    <div className="planRow">
      <b className="align-left">Nombre:</b> <p className="align-right">{plan.nombre}</p>
    </div>
    <div className="planRow">
    <b className="align-left">Costo:</b> <p className="align-right">$ {plan.precio}.00 {plan.moneda}</p>
    </div>
    <div className="planRow">
    <b className="align-left">Usuarios:</b> <span align-left>.</span><p className="align-right">Hasta {plan.usuarios}</p>
    </div>
  </div>
<div className="buttonPaypalContainer">
  <PayPalScriptProvider options={paypalOptions}>
    <PayPalButtons
      style={{ color: "blue" }}
      createSubscription={createSubscription}
      onApprove={onApprove}
    />
  </PayPalScriptProvider>
</div>
    </Modal>
  );
}
