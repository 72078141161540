interface ServiceRoutes {
  [key: string]: string;
}

export const productionRoutes: ServiceRoutes = {
  /**Api autenticacion */
  logoutURL: "https://api-autenticacion.techcode.tech/api/autenticacion/logout",
  registroURL: "https://api-autenticacion.techcode.tech/api/registrar-usuario",
  loginURL: "https://api-autenticacion.techcode.tech/api/autenticacion/login",

  /**Api suscripciones */
  consultarPaquetes:
    "https://ms-techcode-administracion-suscripciones.techcode.tech/api/planes/consultar",
  createSuscription:
    "https://dev.ms-techcode-administracion-suscripciones.techcode.tech/suscripciones/registrar-suscripcion",
};

export const developmentRoutes: ServiceRoutes = {
  /**Api autenticacion */
  logoutURL: "http://localhost:8001/api/autenticacion/logout",
  registroURL: "http://localhost:8001/api/registrar-usuario",
  loginURL: "http://localhost:8001/api/autenticacion/login",
  /**Api suscripciones */
  createSuscription: "http://localhost:8000/api/suscripciones/registrar-suscripcion",
  consultarPaquetes: "http://localhost:8000/api/planes/consultar-formateo",
};

export function getServiceRoutes(environment: string): ServiceRoutes {
  switch (environment) {
    case "development":
      return developmentRoutes;
    case "production":
      return productionRoutes;
    default:
      throw new Error("Environment not supported");
  }
}
