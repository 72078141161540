export const webpagesData = [
    {
        id: 1,
        title: "Landing Page",
        features: [
            {
                id: 1,
                description: "Diseño totalmente personalizado"
            },
            {
                id: 2,
                description: "Dominio y Hosting gratis por 1 año"
            },
            {
                id: 3,
                description: "Certificado SSL para proteger tu sitio web"
            },
            {
                id: 4,
                description: "1 Landing page con 4 bloques de contenido"
            },
            {
                id: 5,
                description: "5 Cuentas de correo empresarial"
            },
            {
                id: 6,
                description: "Hasta 8 imágenes de nuestro catálogo"
            },
            {
                id: 7,
                description: "Alta en buscadores como Google, Bing y Yahoo"
            },
            {
                id: 8,
                description: "Diseño web multidispositivos"
            },
            {
                id: 9,
                description: "Botón de WhatsApp"
            },
            {
                id: 10,
                description: "Formulario de contacto"
            },
            {
                id: 11,
                description: "Propuesta de diseño"
            },
            {
                id: 12,
                description: "Entrega en 5 días hábiles"
            },
        ],
        pricing: "2,499"
    },
    {
        id: 2,
        title: "Plan Empresarial",
        features: [
            {
                id: 1,
                description: "Diseño totalmente personalizado"
            },
            {
                id: 2,
                description: "Dominio y Hosting gratis por 2 años"
            },
            {
                id: 3,
                description: "Certificado SSL para proteger tu sitio web"
            },
            {
                id: 4,
                description: "100 cuentas de correo empresarial"
            },
            {
                id: 5,
                description: "Página web con 15 secciones a elegir"
            },
            {
                id: 6,
                description: "Hasta 50 imágenes de nuestro catálogo"
            },
            {
                id: 7,
                description: "Alta en buscadores como Google, Bing y Yahoo"
            },
            {
                id: 8,
                description: "Diseño web multidispositivos"
            },
            {
                id: 9,
                description: "Integración de redes sociales"
            },
            {
                id: 10,
                description: "Botón de WhatsApp"
            },
            {
                id: 11,
                description: "Formulario de contacto"
            },
            {
                id: 12,
                description: "Entrega en 10 días hábiles"
            },
        ],
        pricing: "7,999"
    },
    {
        id: 3,
        title: "Plan básico",
        features: [
            {
                id: 1,
                description: "Diseño totalmente personalizado"
            },
            {
                id: 2,
                description: "Dominio y Hosting gratis por 1 año"
            },
            {
                id: 3,
                description: "Certificado SSL para proteger tu sitio web"
            },
            {
                id: 4,
                description: "20 cuentas de correo empresarial"
            },
            {
                id: 5,
                description: "Página web con 7 secciones a elegir"
            },
            {
                id: 6,
                description: "Hasta 20 imágenes de nuestro catálogo"
            },
            {
                id: 7,
                description: "Alta en buscadores como Google, Bing y Yahoo"
            },
            {
                id: 8,
                description: "Diseño web multidispositivos"
            },
            {
                id: 9,
                description: "Integración de redes sociales"
            },
            {
                id: 10,
                description: "Botón de WhatsApp"
            },
            {
                id: 11,
                description: "Formulario de contacto"
            },
            {
                id: 12,
                description: "Entrega en 7 días hábiles"
            },
        ],
        pricing: "3,999"
    },
]

export const online_storeData = [
    {
        id: 1,
        title: "Lorem Ipsum",
        features: [
            {
                id: 1,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 2,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 3,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 4,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 5,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 6,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 7,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 8,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 9,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 10,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 11,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 12,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
        ],
        pricing: "2,499"
    },
    {
        id: 2,
        title: "Lorem Ipsum",
        features: [
            {
                id: 1,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 2,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 3,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 4,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 5,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 6,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 7,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 8,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 9,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 10,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 11,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 12,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
        ],
        pricing: "7,999"
    },
    {
        id: 3,
        title: "Lorem Ipsum",
        features: [
            {
                id: 1,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 2,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 3,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 4,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 5,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 6,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 7,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 8,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 9,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 10,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 11,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
            {
                id: 12,
                description: "Lorem Ipsum el texto de relleno de texto."
            },
        ],
        pricing: "3,999"
    },
]