import { useState, useEffect } from "react";

interface Prom {
    data:Response,
    loading:boolean
 }
 
 interface Response{
     codigo:number,
     mensaje:string,
     payload:any
 }

 const initialResponse: Response = {
    codigo: 0,
    mensaje: "",
    payload: [],
};

export function useFetch(url: string, method: string = 'GET', body?: any,token?:boolean,) {
    const [data, setData] = useState(initialResponse);
    const [loading, setLoading] = useState(false); 


    type Headers = {
        'Content-Type': string;
        'Authorization'?: string; 
    };
    
    const fetchData = () => {
        setLoading(true);
        let headers: Headers = {
            'Content-Type': 'application/json'
        };
    
        const barerToken = localStorage.getItem('token');
        if (token) {
            headers['Authorization'] = barerToken!;
        }
    
        fetch(url, {
            method: method,
            body: body ? JSON.stringify(body) : null,
            headers: headers
        })
        .then(response => response.json())
        .then(json => {
            setData(json);
            setLoading(false);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
        });
    };


    return { data, loading, fetchData }; 
}