import React, { useState, useEffect } from 'react';
import { NavBar } from './layouts/nav-bar/Nav'
import { Inicio } from './components/inicio';
import { Servicios } from './components/cartera-servicios';
import { Nosotros } from './components/nosotros';
import { Oferta } from './components/oferta-software';
import { Footer } from './layouts/footer';
import Container from 'react-bootstrap/Container';

export default function APP() {
  const [token, setToken] = useState(false);
  const [state, setState] = useState(true);

  const isLoginFunction = () =>{
    setToken(true)
    setTimeout(() => {
      setState(true)
    }, 100);
  }

  return (
    <>

      <Container fluid>
        {state &&
          <NavBar setToken={token}/>
        }
        <Inicio />
        <Servicios/>
        <Nosotros isLoginFunction={() =>{
          setState(false)
          isLoginFunction()}} />
        <Oferta />
        <Footer />  
      </Container>
    </>

  );
}
