import React from 'react';

interface DropdownProps {
    name: string;
    OpenModal: (tipo: string) => void;
    redirectoTo: () => void;
}

export function Dropdown({ name, OpenModal, redirectoTo }: DropdownProps) {
    return (
        <li className="dropdownItem">
            {name === 'Registrarme' && 
                <a onClick={() => OpenModal('registro')}>{name}</a>
            }
            {name === 'Entrar a mi cuenta' && 
                <a onClick={() => OpenModal('login')}>{name}</a>
            }
            {name === 'Cerrar Sesión' && 
                <a onClick={() => OpenModal('logout')}>{name}</a>
            }
            {name === 'Mensaje' && 
                <a onClick={() => console.log('fff')}>{name}</a>
            }
            {name !== 'Mensaje' && name !== 'Entrar a mi cuenta' && name !== 'Registrarme' && name !== 'Cerrar Sesión' &&
                <a onClick={() => redirectoTo()}>{name}</a>
            }
        </li>
    );
}