import './styles/Oferta.css'
import { webpagesData, online_storeData } from './Oferta.data'
import { MotionTransition } from "../../utils/MotionTansition"
import { useState } from 'react';

export function Oferta() {//calc(50% + 22px)

    const [ovalPosition, setOvalPosition] = useState({ left: '28px' });
    const [selectedOption, setSelectedOption] = useState('webpages');

    const handleOnlineStoreClick = () => {
        setOvalPosition({ left: 'calc(50% + 10px)' });
        setSelectedOption('oline_store');
    }

    const handleWebpagesClick = () => {
        setOvalPosition({ left: '28px' });
        setSelectedOption('webpages');
    }

    return (
        <div className='oferta-container' id='Paquetes y precios'>
            <MotionTransition>
                <p className='title-right'>Desata el potencial de tu negocio</p>
            </MotionTransition>
            <h2>Paquetes y precios</h2>
            <p className='title-adding'>Encuentra un plan a tu medida</p>
            <div className='options-services'>
                <div className="container-options">
                    <label className='selected' style={ovalPosition}></label>
                    <div className='webpages'>
                        <button onClick={handleWebpagesClick}>Páginas web</button>
                    </div>
                    <div className='online-store'>
                        <button onClick={handleOnlineStoreClick}>Tienda online</button>
                    </div>
                </div>
            </div>
            <div className='pricing'>
                {selectedOption === 'webpages' && webpagesData.map(({ id, title, features, pricing }) => (
                    <div className="pricing-box" key={id}>
                        <h3>{title}</h3>
                        <p>Incluye:</p>
                        <ul className='card_ul'>
                            {features.map(({ id, description }) => (
                                <li key={id}>{description} <hr /></li>

                            ))}
                        </ul>
                        <p className='money'><span>$</span>{pricing} <span>MXN + IVA</span></p>
                        <div className='want-it'>
                            <button>¡LO QUIERO!</button>
                        </div>
                    </div>
                ))}

                {selectedOption === 'oline_store' && online_storeData.map(({ id, title, features, pricing }) => (
                    <div className="pricing-box" key={id}>
                        <h3>{title}</h3>
                        <p>Incluye:</p>
                        <ul>
                            {features.map(({ id, description }) => (
                                <li key={id}>{description} <hr /></li>

                            ))}
                        </ul>
                        <p className='money'><span>$</span>{pricing} <span>MXN + IVA</span></p>
                        <div className='want-it'>
                            <button>¡LO QUIERO!</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}