import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { useFetch } from "../../../Services/suscripciones/useFetch";
import {planes} from "./paquetes.data"
import { productionRoutes } from "../../../environments/environments";
import { SpinnerFunction } from "../../../utils/spinner/spinnerFunction";
import "./styles/paquetes.css";

interface PaquetesProps {
  isShow: boolean;
  handleClose: (goto?: string) => void;
  handleOpenPaypal: (idPlanPaypal: string) => void;
}

interface Prom {
  data: Response;
  loading: boolean;
  fetchData: () => void;
}

interface Response {
  codigo: number;
  mensaje: string;
  payload: Array<paquete>;
}

interface paquete {
  id: number,
  idPlanPaypal: string,
  idRol: number,
  nombre: string,
  precio: number,
  usuarios: number,
  moneda: string,
  frecuencia: string,
  iva: string,
  ivaIncluido: number,
  fechaCreacion: string,
  descripcion: string,
  estatus: string,
  updated_at: string,
  created_at: string
}

interface Plan {
  id: number;
  nombre: string;
  features?: Descripcion[];
  descripcion: string;
  precio: number;
  idPlanPaypal: string;
  idBlock?: number;
  usuarios: number;
}

interface Descripcion {
  id: number;
  description: string;
}

export function Paquetes({
  isShow,
  handleClose,
  handleOpenPaypal,
}: PaquetesProps) {
  const [url, setUrl] = useState(productionRoutes.consultarPaquetes);
  const [body, setBody] = useState();
  const [tipoService, setTipoService] = useState("GET");
  const [tokenService, setTokenService] = useState(false);
  const [dataPlanes, setDataPlanes] = useState(Array<Plan>);
  const [selectedOption, setSelectedOption] = useState("");

  const { data, loading, fetchData }: Prom = useFetch(
    url,
    tipoService,
    body,
    tokenService
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleRedirect = (goTo: string) => {
    handleClose(goTo);
  };

  const handleSelectChange = (
    usuarios: any,
    indexdPlan: any,
    indexBloque: number
  ) => {
    let idDescripciones = 1;
    let planesTemporal = [...dataPlanes];
    console.log(usuarios.target.value);
    planes[indexBloque].forEach((element: any) => {
      console.log(element);
      if (element.usuarios === parseInt(usuarios.target.value)) {
        console.log("validado", element);
        const descripciones = element.descripcion
          .split(",")
          .map((desc: string) => {
            return { id: idDescripciones++, description: desc.trim() };
          });
        planesTemporal[indexdPlan] = element;
        planesTemporal[indexdPlan].features = descripciones;
        planesTemporal[indexdPlan].idBlock = indexBloque;
        setDataPlanes(planesTemporal);
      }
    });
  };

  useEffect(() => {
    if (isShow ) {
      fetchData();
    }
  }, [isShow]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    let idDescripciones = 1;
    let planesTemporal: Array<Plan> = [];
    if (data.payload) {
      planes.forEach((plan: any, index: number) => {
        const descripciones = plan[0].descripcion
          .split(",")
          .map((desc: string) => {
            return { id: idDescripciones++, description: desc.trim() };
          });
        planesTemporal[index] = plan[0];
        planesTemporal[index].features = descripciones;
        planesTemporal[index].idBlock = index;
      });
      setDataPlanes(planesTemporal);
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <>
          <SpinnerFunction />
        </>
      ) : (
        <>
          <Modal
            show={isShow}
            fullscreen={true}
            onHide={handleClose}
            className="modal-paquetes"
          >
            <Modal.Header className="modal-cabecera" closeButton>
              <Modal.Title>
                <img
                  className="logotupyme"
                  src="/assets/tupyme.png"
                  alt="Imagen de tuPyme"
                  width="200"
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-paquetes">
              <h3>Elije el plan que mas se ajuste a tus necesidades</h3>
              <Row className="pricing">
                {dataPlanes?.map((plan: any, index) => (
                  <Col xs={6} md={3} className="pricing-box">
                    <h4>{plan.nombre}</h4>
                    <p>Incluye:</p>
                    <ul className="descriptionList">
                      {plan.features?.map(
                        ({ id, description }: Descripcion) => (
                          <li>
                            {description} <hr />
                          </li>
                        )
                      )}
                    </ul>
                    <div className="slect-container">
                    <select
                      className="custom-select"
                      value={plan.usuarios}
                      onChange={(e) =>
                        handleSelectChange(e, index, plan.idBlock!)
                      }
                    >
                      {planes[plan.idBlock!].map((item: any, i: any) => (
                        <option value={item.usuarios}>
                          {item.usuarios} Usuarios
                        </option>
                      ))}
                    </select>
                    </div>
                    <p className="money">
                      <span>$</span>
                      {plan.precio} <span>MXN + IVA</span>
                    </p>
                    <div className="want-it">
                      {token ? (
                        <button onClick={() => handleOpenPaypal(plan)}>
                          ¡LO QUIERO!
                        </button>
                      ) : (
                        <button
                          onClick={() => handleRedirect("Entrar a mi cuenta")}
                        >
                          ¡LO QUIERO!
                        </button>
                      )}
                    </div>
                  </Col>
                ))}
              </Row>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}
