import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from "react-bootstrap/Modal";

import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { SpinnerFunction } from "../../utils/spinner/spinnerFunction";

import { developmentRoutes } from "../../environments/environments";

import "./styles/presupuesto.css";
import { useFetch } from "../../Services/suscripciones/useFetch";

interface PresupuestoProps {
    isShow: boolean;
    handleClose: (goto?: string) => void;
}

interface Prom {
    data: Response;
    loading: boolean;
    fetchData: () => void;
}

interface Response {
    codigo: number;
    mensaje: string;
    payload: any;
}

export function Presupuesto({ isShow, handleClose }: PresupuestoProps) {
    //Conexión servicio useFetch
    const [url, setUrl] = useState(developmentRoutes.consultarPaquetes);
    const [tipoService, setTipoService] = useState("GET");
    const [body, setBody] = useState();
    const [tokenService, setTokenService] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const { data, loading, fetchData }: Prom = useFetch(
        url,
        tipoService,
        body,
        tokenService
    );

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            {loading ? (
                <>
                    <SpinnerFunction />
                </>
            ) : (
                <>
                    <Modal
                        show={isShow}
                        fullscreen={true}
                        onHide={handleClose}
                        className="modal-paquetes">
                        <Modal.Header closeButton>
                            <Modal.Title>SOLO TEST</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container fluid="md" className="selections_container">
                                <Row className="justify-content-between">
                                    <Col xs={12} md={6} lg={4}>
                                        <Form.Select className="select-options form-select" aria-label="Default select example">
                                            <option>Open this select menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <Form.Select className="select-options form-select" aria-label="Default select example">
                                            <option>Open this select menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <Form.Select className="select-options form-select" aria-label="Default select example">
                                            <option>Open this select menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row className="justify-content-between">
                                    <Col xs={12} md={6} lg={4}>
                                        <Form.Select className="select-options form-select" aria-label="Default select example">
                                            <option>Open this select menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <Form.Select className="select-options form-select" aria-label="Default select example">
                                            <option>Open this select menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <Form.Select className="select-options form-select" aria-label="Default select example">
                                            <option>Open this select menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6} lg={4}>
                                        <Form.Select className="select-options form-select" aria-label="Default select example">
                                            <option>Open this select menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <Form.Select className="select-options form-select" aria-label="Default select example">
                                            <option>Open this select menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <Form.Select className="select-options form-select" aria-label="Default select example">
                                            <option>Open this select menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                    </Modal>
                </>
            )
            }
        </>
    )
}