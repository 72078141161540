import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

interface ContactProps {
  show: boolean;
  handleClose: (formData?: any) => void;
}

export function Contacto({ show, handleClose }: ContactProps) {
  const [formData, setFormData] = useState({
    email: "",
    tel: "",
    name: "",
    message:""
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value.toLowerCase(),
    });
  };

  const handleSubmit = () => {
    setFormData({
        email: "",
        tel: "",
        name: "",
        message:""
    });
    handleClose('exito');
  };

  const handleCleanPrevClos = () => {
    setFormData({
        email: "",
        tel: "",
        name: "",
        message:""
    });
    handleClose();
  };

  return (
    <div>
      <Modal className="modalRegistro" show={show} onHide={handleCleanPrevClos}>
        <Modal.Header className="modalBody" closeButton>
          <Modal.Title className="title">Contáctanos</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          <Form className="formulario">
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Control
                type="text"
                name="email"
                placeholder="Correo Electrónico"
                value={formData.email}
                onChange={handleInputChange}
                className="inputStyle"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formTel">
              <Form.Control
                type="number"
                name="tel"
                max={10}
                placeholder="Teléfono"
                value={formData.tel}
                onChange={handleInputChange}
                className="inputStyle"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formNombre">
              <Form.Control
                type="text"
                name="name"
                placeholder="Nombre"
                value={formData.name}
                onChange={handleInputChange}
                className="inputStyle"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formMensaje">
              <Form.Control
                as="textarea"
                rows={3}
                name="message"
                placeholder="Mensaje"
                value={formData.message}
                onChange={handleInputChange}
                className="inputStyle"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="modalFooter">
          <Button
            className="btnGuardar"
            variant="primary"
            onClick={handleSubmit}
            disabled={!formData.message || !formData.email }
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
