import './styles/Nosotros.css'
import { Swiper, SwiperSlide } from "swiper/react";
import dataNosotros from './Nosotros.data';
import { MotionTransition } from "../../utils/MotionTansition"
import { Paquetes } from '../../layouts/tupyme/paquetes/Paquetes';
import SubscriptionButton from "../../layouts/tupyme/pypal/SubscriptionButton";
import {Registro} from '../../layouts/Registro/Registro';
import {FormFactura} from "../../layouts/tupyme/paquetes/formFactura"
import Alert from 'react-bootstrap/Alert';
import { useState } from 'react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

interface propsFunction {
    isLoginFunction: () => void;
  }

export function Nosotros({ isLoginFunction }: propsFunction) {
    const [isModalOpenTuPyme, setIsModaltuOpenTuPyme] = useState(false);
    const [isRegisterOpen, setisRegisterOpen] = useState(false);
    const [isFacturaOpen, setFacturaOpen] = useState(false);
    const [formRegistro, setformRegistro] = useState({});
    const [from, setFrom] = useState('');
    const [isModalPaypalOpen, setIsModalPaypalOpen] = useState(false);
    const [planId, setPlanId] = useState({});
    const [typeAlert, setTypeAlert] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const handleModalTuPyme = async (open:boolean,goTo?:string) => {
         if(goTo){
            setFrom(goTo!);
            setIsModaltuOpenTuPyme(false);
            setisRegisterOpen(true);
        }else{
            setIsModaltuOpenTuPyme(open);
        }
    };
    
    const handleModalPaypal = async (open:boolean,plan?:any) => {
        setIsModaltuOpenTuPyme(false);
        if(plan && plan.permisos.find((permiso:any) => permiso.idPermiso === 71)){
            setFacturaOpen(true)
            setPlanId(plan!)
        }else{
            if(open === true){
                setPlanId(plan!)
                if(planId !== ''){
                setIsModaltuOpenTuPyme(false);
                }
            }else{
                setIsModaltuOpenTuPyme(true);
            }
            setIsModalPaypalOpen(open);
        }  
    };

    const handleCloseLoginModal = (mensaje:string,codigo:number) =>{  
        isLoginFunction();
        if(mensaje){
            setShowAlert(true)
            if(codigo === 200){
                setTypeAlert('success');
            }else{
                setTypeAlert('danger');
            }
            setMensaje(mensaje);
          }
          setisRegisterOpen(false);
        }
    

   

    const swiperOptions = {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        loop: true,
        slidesPerView: "auto",
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        modules: [EffectCoverflow, Pagination, Navigation],
        className: 'swiper_container',
    };

    const handleCloseFormFactura = (formData:any) =>{
        if(formData){
            setFacturaOpen(false);
            setformRegistro(formData);
            setIsModalPaypalOpen(true);
        }else{
            setFacturaOpen(false);
            setIsModaltuOpenTuPyme(true);
        }
        
    }


    return (
        <div className='nosotros-container' id="Nuestro software">
            <MotionTransition>
                <p className="solution">Soluciones personalizadas</p>
            </MotionTransition>
            <h2>Oferta de software <br />(Nuestros Proyectos)</h2>
            <MotionTransition>
                <div className='tupyme-container'>
                    <img onClick={() => handleModalTuPyme(true)} className="logotupyme" src="/assets/tupyme.png" alt="Imagen de tuPyme" width="300" />
                    <img className='arrow' src="/assets/icons/arrow-back-double.png" alt="" width="20" height="20" />
                </div>
            </MotionTransition>
            <div className='punto-venta-container'>
                <div className='punto-venta-left'>
                    <img src="/assets/icons/punto-venta.png" alt="" width="228" />
                    <p className='punto-venta-title'>Punto de venta</p>
                    <p>Administra tu negocio con tecnología de última generación</p>
                </div>
                <div className='punto-venta-right'>
                    <Swiper {...swiperOptions}>
                        {dataNosotros.map(({ id, name, description, services, icon }) => (
                            <SwiperSlide key={id} className='card' id={`card-${String(id)}`}>
                                <img src={icon} alt="Imagen" width="80" height="80" />
                                <p className='title'>{name}</p>
                                <p>{description}</p>
                                <ul>
                                    {services.map(({ id, service }) => (
                                        <li key={id}>{service}</li>
                                    ))}
                                </ul>
                            </SwiperSlide>
                        ))}

                        <div className="swiper-button-prev slider-arrow">
                            <img src="/assets/icons/left-arrow.png" alt="" width="80" />
                        </div>
                        <div className="swiper-button-next slider-arrow">
                            <img src="/assets/icons/right-arrow.png" alt="" width="80" />
                        </div>
                    </Swiper>
                </div>
            </div>
            <div className='sistema-administrativo-container'>
                <div className='sistema-administrativo-right'>
                    <Swiper {...swiperOptions}>
                        {dataNosotros.map(({ id, name, description, services, icon }) => (
                            <SwiperSlide key={id} className='card' id={`card-${String(id)}`}>
                                <img src={icon} alt="Imagen" width="80" height="80" />
                                <p className='title'>{name}</p>
                                <p>{description}</p>
                                <ul>
                                    {services.map(({ id, service }) => (
                                        <li key={id}>{service}</li>
                                    ))}
                                </ul>
                            </SwiperSlide>
                        ))}

                        <div className="swiper-button-prev slider-arrow">
                            <img src="/assets/icons/left-arrow.png" alt="" width="80" />
                        </div>
                        <div className="swiper-button-next slider-arrow">
                            <img src="/assets/icons/right-arrow.png" alt="" width="80" />
                        </div>
                    </Swiper>
                </div>
                <div className='sistema-administrativo-left'>
                    <img src="/assets/icons/sistemaAdmin.png" alt="" width="228" />
                    <p className='sistema-administrativo-title'>Sistema administrativo</p>
                    <p>Suite completa de software para la administración empresarial</p>
                </div>
            </div>
            <FormFactura show={isFacturaOpen} handleClose={(formData:any) =>{handleCloseFormFactura(formData)}}></FormFactura>
            <Paquetes isShow={isModalOpenTuPyme} handleClose={(goTo) => handleModalTuPyme(false,goTo!)} handleOpenPaypal={(plan) => handleModalPaypal(true,plan)}></Paquetes>
            {isModalPaypalOpen &&
          <SubscriptionButton isOpen={isModalPaypalOpen} isClose={() => handleModalPaypal(false)} plan={planId} formRegistro={formRegistro}/>
        }
      <Registro show={isRegisterOpen} from={from} handleClose={(mensaje,codigo) => {handleCloseLoginModal(mensaje!,codigo!)}}/>
      {showAlert &&(
        <Alert className="alerta-registro"  variant={typeAlert} onClose={() => setShowAlert(false)} dismissible>
        <Alert.Heading>{mensaje}</Alert.Heading>
        </Alert>
          )}
        </div>
    )
}