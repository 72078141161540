import React, { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import {useFetch} from '../../Services/suscripciones/useFetch';
import { productionRoutes } from '../../environments/environments';
import { SpinnerFunction } from '../../utils/spinner/spinnerFunction';
import './styles/Registro.css'

interface RegistroProps {
    show: boolean,
    handleClose: (mensaje?:string,codigo?:number) => void,
    from:string
}

interface Prom {
    data:any,
    loading:boolean,
    fetchData:() => void;
 }
 
 interface Response{
     codigo:number,
     mensaje:string,
     payload:any,
     error:string
 }

export function Registro({ show, handleClose, from }: RegistroProps) {
    const [fromState,setFromState] =useState('')
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        password: '',
        username:''
    });

    let url = "";

    if(fromState === 'registro'){
        url = productionRoutes.registroURL;
    } else {
        url = productionRoutes.loginURL;
    }
    const { data, loading,fetchData }: Prom = useFetch(
        url,'POST',formData
      );

     

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if(name === 'name'){
         const nombreCompleto = value.split(' ');
         const userName = nombreCompleto[0];
        setFormData({
            ...formData,
            [name]: value.toLowerCase(),
            username:userName
        });
    }else{
        setFormData({
            ...formData,
            [name]: value.toLowerCase(),
        });
    }
    };

    const handleSubmit = () => {
        fetchData();
    };

    const handleCleanPrevClos = () =>{
        setFormData({
            email: '',
            name: '',
            password: '',
            username:''
        });
        handleClose()
    }

    const handleChangeFrom = (fromProp:string) =>{
        if('registro'){
            setFromState(fromProp);
        }else{
            setFromState('Entrar a mi cuenta');
        }
       
    }

    useEffect(() => {
        if(data.payload && data.payload.token){
            localStorage.setItem('token', data.payload.token);
            const usuarioString = JSON.stringify(data.payload.usuario);
          localStorage.setItem('usuario', usuarioString);
        }
        if(data.codigo){
            handleClose(data.mensaje,data.codigo);
        }else{
            handleClose(data.error);
        } 
        
      }, [data]);

      useEffect(() => {
        if(from === 'login'){
            setFromState('Entrar a mi cuenta')
        }else{
            setFromState(from)
        }
      }, [from]);

    return (
        <>
        {loading ? (
    <>
    <SpinnerFunction/>
    </>
  ) : (
    <>
        <Modal className='modalRegistro'  show={show} onHide={handleCleanPrevClos}>
            <Modal.Header className='modalBody'  closeButton>
                {fromState === 'registro' ?(
                    <Modal.Title className='title'>Registrarse</Modal.Title>
                ):(
                <Modal.Title className='title'>Iniciar Sesión</Modal.Title>
                )
                }
                
            </Modal.Header>
            <Modal.Body className='modalBody' >
                <Form className='formulario'>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder="Correo Electrónico"
                            value={formData.email}
                            onChange={handleInputChange} 
                            className='inputStyle'
                        />
                    </Form.Group>
                   {fromState === 'registro' &&
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder="Nombre"
                            value={formData.name}
                            onChange={handleInputChange} 
                            className='inputStyle'
                        />
                    </Form.Group>
                     }
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Control
                            type="password"
                            name="password"
                            placeholder="Contraseña"
                            onChange={handleInputChange} 
                            className='inputStyle'
                        />
                    </Form.Group>
                </Form>
                {fromState === 'Entrar a mi cuenta' ?(
                <>
                    <p className='parrafoRegistro'>Si aún no te haz registrado hazlo </p><p className='parrafoCambio' onClick={() =>{handleChangeFrom('registro')}}>ahora</p>
                    </>
                ):(
                    <>
                    <p className='parrafoRegistro'>Si ya estás registrado </p><p className='parrafoCambio' onClick={() =>{handleChangeFrom('Entrar a mi cuenta')}}>inicia Sesión</p>
                    </>
                )
                }
            </Modal.Body>
            <Modal.Footer  className='modalFooter'>
            {fromState === 'registro' ?(
                <Button className='btnGuardar' variant="primary" onClick={handleSubmit} disabled={!formData.email || !formData.name || !formData.password}>
                    Enviar
                </Button>
            ) :(
                <Button className='btnGuardar' variant="primary" onClick={handleSubmit} disabled={!formData.email || !formData.password}>
                    Entrar
                </Button>
            )
             }
            </Modal.Footer>
        </Modal>
        </>
  )}
    </>
    );
} 