export const dataServicios = [
    {
        id: 1,
        name: "Soluciones en la nube",
        description: "Las soluciones en la nube son servicios y soluciones que operan a través de Internet utilizando tecnología de computación en línea.",
        services: [
            {
                id: 1,
                service: "Desarrollo de aplicaciones"
            },
            {
                id: 1,
                service: "Desarrollo de software"
            }
        ],
        icon: "/assets/icons/ic_cloudsolutions.png"
    },
    {
        id: 2,
        name: "Desarrollo web",
        description: "El desarrollo y la ingenieria web implican crear y mantener sitios web y aplicaiones en linea , abarcando diseño, desarrollo e implementación.",
        services: [
            {
                id: 1,
                service: "Desarrollo Full Stack"
            },
            {
                id: 1,
                service: "Diseño web responsivo"
            }
        ],
        icon: "/assets/icons/ic_desarrolloweb.png"
    },
    {
        id: 3,
        name: "Ciberseguridad",
        description: "La ciberseguridad protege sistemas, redes y datos de robos y accesos no autorizados, implementando protocolos de protección avanzados.",
        services: [
            {
                id: 1,
                service: "Seguridad web"
            },
            {
                id: 1,
                service: "Seguridad de red"
            }
        ],
        icon: "/assets/icons/ic_ciberseguridad.png"
    },
]