"use client"
import './styles/Servicios.css';
import { dataServicios } from "./Servicios.data";
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { Swiper, SwiperSlide } from "swiper/react";
import React from 'react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

export function Servicios() {
    return (
        <div className='servicios-container' id="Nuestros servicios">
            <h2>NUESTROS SERVICIOS</h2>
            <Container>
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    loop={true}
                    slidesPerView={"auto"}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 100,
                        modifier: 2.5,
                    }}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                    modules={[EffectCoverflow, Pagination, Navigation]}
                    className="swiper_container"
                >
                    {dataServicios.map(({ id, name, description, services, icon }) => (
                        <SwiperSlide key={id} className='card' id={`card-${String(id)}`}>
                            <img src={icon} alt="Imagen" width="80" height="80" />
                            <p className='title'>{name}</p>
                            <p>{description}</p>
                            <ul>
                                {services.map(({ id, service }) => (
                                    <li key={id}>{service}</li>
                                ))}
                            </ul>
                        </SwiperSlide>
                    ))}

                    <div className="swiper-button-prev slider-arrow">
                        <img src="/assets/icons/left-arrow.png" alt="" width="80" />
                    </div>
                    <div className="swiper-button-next slider-arrow">
                        <img src="/assets/icons/right-arrow.png" alt="" width="80" />
                    </div>
                </Swiper>
            </Container>
        </div>
    )
}