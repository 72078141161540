export const dataNav = [
    {
      id: 1,
      name: "Here Us",
      idLink: "#nosotros",
      options: [
        {
          id: 1,
          name: "Paquetes y precios",
          url: "#Nosotros"
        },
        {
          id: 2,
          name: "Tu presupuesto",
          url: "#Nosotros"
        },
        {
          id: 3,
          name: "Mensaje",
          url: "#Nosotros"
        }
      ]
    },
    {
      id: 2,
      name: "Menu",
      idLink: "#menu",
      options: [
        {
          id: 1,
          name: "Nuestros servicios",
          url: "#Nosotros"
        },
        {
          id: 2,
          name: "Nuestro software",
          url: "#Nosotros"
        }
      ]
    },
    {
      id: 3,
      name: "Login",
      idLink: "#login",
      options: [
        {
          id: 1,
          name: "Entrar a mi cuenta",
          url: "#Nosotros"
        },
        {
          id: 2,
          name: "Registrarme",
          url: "#Nosotros"
        }
      ]
    },
  ];