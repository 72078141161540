import "./styles/Footer.css";
import Container from "react-bootstrap/Container";
import { iconsInicio } from "../../components/inicio/Inicio.data";
import { MotionTransition } from "../../utils/MotionTansition";
import { useState } from "react";
import { Contacto } from "../contacto/Contacto";
import { Presupuesto } from "../presupuesto";
import Alert from 'react-bootstrap/Alert';

export function Footer() {
  const [isOpenContacto, setIsOpenContacto] = useState(false);
  const [isOpenPresupuesto, setIsOpenPresupuesto] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [from, setFrom] = useState('');
  const [typeAlert, setTypeAlert] = useState("");
  const [message, setMessage] = useState("");


  const handleOpenForm = () => {
    setIsOpenContacto(true);
  };

  const handleOpenPresupuesto = () => {
    setIsOpenPresupuesto(true);
  };

  const handleCloseFormContacto = (respuesta: string) => {
    if (respuesta === "exito") {
      console.log();
    } else {
      console.log('');
    }
    if (respuesta) {
      setShowAlert(true);
      setIsOpenContacto(false);
      setTypeAlert("success");
      setMessage('¡Tu solicitud, Se ha enviado de manera exitosa!')
    } else {
      setIsOpenContacto(false);
    }

  };

  const handleModalTuPyme = async (open: boolean, goTo?: string) => {
    if (goTo) {
      setFrom(goTo!);
      setIsOpenPresupuesto(false);
    } else {
      setIsOpenPresupuesto(open);
    }
  };

  return (
    <div className="footer-container">
      <MotionTransition>
        <p className="title-left">Excelencia y calidad a la mano</p>
      </MotionTransition>
      <div className="button-options">
        <button
          onClick={() => {
            handleOpenForm();
          }}
        >
          Contáctanos
        </button>
        <button onClick={() => { handleOpenPresupuesto(); }}>Presupuesto</button>
      </div>
      <div className="first-screen">
        <Container className="first-header">
          <img src="/assets/logoTechCode.png" alt="Logo" />
        </Container>
        <p>
          DATOS, SOFTWARE Y PLATAFORMAS. <br />
          EFICIENCIA MÁXIMA, ÉXTITO SOSTENIBLE
        </p>
        <div className="social-media">
          {iconsInicio.map(({ id, image }) => (
            <div key={id}>
              <img
                src={image}
                className="link-nav"
                width="40"
                alt="icon"
                height="40"
              />
            </div>
          ))}
        </div>
      </div>
      <Contacto
        show={isOpenContacto}
        handleClose={(respuesta: any) => {
          handleCloseFormContacto(respuesta);
        }}
      ></Contacto>
      {showAlert && (
        <Alert
          className="alerta-registro"
          variant={typeAlert}
          onClose={() => setShowAlert(false)}
          dismissible
        >
          <Alert.Heading>{message}</Alert.Heading>
        </Alert>
      )}
      <Presupuesto isShow={isOpenPresupuesto} handleClose={(goTo) => handleModalTuPyme(false, goTo!)}></Presupuesto>
    </div >
  );
}
