import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/nav.css';
import { Dropdown } from '../dropdown';
import { dataNav } from './Nav.data';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Registro} from '../Registro/Registro';
import Alert from 'react-bootstrap/Alert';
import {useFetch} from '../../Services/suscripciones/useFetch';
import { productionRoutes } from '../../environments/environments';
import { SpinnerFunction } from '../../utils/spinner/spinnerFunction';

interface usuario{
    id:number
    name:string,
    email:string,
    idRolAutenticacion:number
}

interface Prom {
    data:any,
    loading:boolean,
    fetchData:() => void;
 }

const usuarioVacio:usuario = {
    id: 0,
    name: '',
    email: '',
    idRolAutenticacion:0
  };

export function NavBar(setToken:any) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [typeAlert, setTypeAlert] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [user, setUser] = useState(usuarioVacio);
    const [from, setIsFrom] = useState('');
    const [dataNavLogin, setDataNavLogin] = useState(dataNav);
    const [openMenuId, setOpenMenuId] = useState<number | null>(null);
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
       
        const handleOutsideClick = (event: MouseEvent) => {
            const composedPath = event.composedPath(); 

            if (
                menuRef.current && 
                !menuRef.current.contains(event.target as Node) && 
                Array.isArray(composedPath) && 
                !composedPath.some(element => { 
                    if (element instanceof Element) { 
                        return element.classList.contains('link-nav');
                    }
                    return false; 
                })
            ) {
                setOpenMenuId(null);
            }
        };

        document.addEventListener('click', handleOutsideClick);
        getToken();

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const  url = productionRoutes.logoutURL;
    const body = '';
    const token = true

    const { data, loading,fetchData }: Prom = useFetch(
        url,'POST',body,token
      );

    useEffect(() => {
        if(data.codigo === 200){
            handleCloseModal(data.mensaje,data.codigo);
            localStorage.clear();
            setDataNavLogin(dataNav);
        }
        if(data.codigo){
            handleCloseModal(data.mensaje,data.codigo);
        }else{
            handleCloseModal(data.error);
        } 

    }, [data]);

    useEffect(() => {
       getToken();
    }, [setToken]);

    

    const handleMenuEnter = (id: number) => {
        setOpenMenuId(id);
    };


    const handleMenuClick = (id: any) => {
        setOpenMenuId(openMenuId === id ? null : id);
    };


  const handleCloseModal = (mensaje?:string,codigo?:number) =>{
    if(mensaje){
        setShowAlert(true)
        if(codigo === 200){
            setTypeAlert('success');
            getToken();
        }else{
            setTypeAlert('danger');
        }
        setMensaje(mensaje);
      }
      setIsModalOpen(false);
    }

   const handleRedirectTo = (route:string) =>{
    const element = document.getElementById(route);
    console.log(route);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    }

    const getToken = () =>{
        const usuarioString = localStorage.getItem('usuario');
        const usuario = JSON.parse(usuarioString!);
        setUser(usuario);

        if(usuario && dataNavLogin[2].name !== usuario.name){
            const perfil = {
                id:3,
                name: usuario.username,
                idLink: "#login",
                options: [
                  {
                    id: 1,
                    name: "Cerrar Sesión",
                    url: ""
                  }
                ]
            }

               setDataNavLogin(dataNav.filter(item => item.id !== 3).concat(perfil));
        }
    }
   

  const handleOpenModal = async (from:string) => {
    if(from === 'logout'){
        setOpenMenuId(null);
        fetchData();
    }else{
    setOpenMenuId(null);
    setIsModalOpen(true);
    setIsFrom(from);
    }
  }

    return (
        <>
          {loading ? (
    <>
    <SpinnerFunction/>
    </>
  ) : (
    <>
        <Navbar expand="lg">
            <Container fluid>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto justify-content-between w-100">
                        {dataNavLogin.map(({ id, name, idLink, options }) => (
                            <Nav.Link key={id}>
                                <a href={idLink} className="link-nav" onClick={() => handleMenuClick(id)} onMouseEnter={() => handleMenuEnter(id)}>{name}</a>
                                <div ref={menuRef} className={`menu_dropdown ${openMenuId === id ? 'active' : 'inactive'}`} id={`menu_drop-${String(id)}`} onMouseEnter={() => handleMenuEnter(id)} >
                                    {options.map(({ id, name, url }, index) => (
                                        <React.Fragment key={id}>
                                            <Dropdown OpenModal={(tipo:string) =>{handleOpenModal(tipo)}} redirectoTo={() =>{handleRedirectTo(name)}} name={name}></Dropdown>
                                            {index < options.length - 1 && <hr />}
                                            </React.Fragment>
                                    ))}
                                </div>
                            </Nav.Link>
                        ))}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <Registro show={isModalOpen} from={from} handleClose={(mensaje,codigo) => {handleCloseModal(mensaje,codigo)}}/>
        {showAlert &&(
        <Alert className="alerta-registro"  variant={typeAlert} onClose={() => setShowAlert(false)} dismissible>
        <Alert.Heading>{mensaje}</Alert.Heading>
        </Alert>
          )}
         </>
  )}
    </>
    );
}