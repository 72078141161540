export const iconsInicio = [
    {
        id: 1,
        name: "Instagram",
        image: "/assets/icons/insta-01.png"
    },
    {
        id: 2,
        name: "Facebook",
        image: "/assets/icons/Face-01.png"
    },
    {
        id: 3,
        name: "Whatsapp",
        image: "/assets/icons/wa-01.png"
    },
];